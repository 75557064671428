import { CheckCircleIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import CheckIcon from '@heroicons/react/24/solid/CheckIcon';

export const tiles = [
  {
    id: 1,
    title: 'Adapting to Your Changing Landscape',
    description:
    'Our team of experts is dedicated to understanding and staying ahead of the evolving needs in the education market. By proactively anticipating shifts, we develop integrated solutions that seamlessly align with the products they support, ensuring your institution stays at the forefront of innovation.',    
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/HR-UT-Perform-Benefits-03.png',
    alt: '',
    className: 'px-6',
    width: '55px',
  },
  {
    id: 2,
    title: 'Optimize Your Budget',
    description:
      'Unlock significant savings for your school or district with bundled products and components available in the Marketplace. These carefully curated bundles not only enhance functionality but also consolidate software solutions across different areas of school operations, all at an affordable price point.',    
      img: 'https://www.powerschool.com/wp-content/uploads/2021/12/Icon-Finance-ROI-Color.png',
    alt: '',
    className: 'px-6 pt-4 md:pt-0',
    width: '55px',
  },
  {
    id: 3,
    title: 'Effortless Integration, Streamlined Operations',
    description:
      'Experience seamless integration with Marketplace solutions. Designed to effortlessly blend into core products, these solutions offer a hassle-free, plug-and-play installation, saving your team time and minimizing potential challenges. Customize your experience with various options, allowing you to implement only what suits your school or district’s specific needs.',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/Icon-3-Clicks-Color.png',
    alt: '',
    className: 'px-6 pt-4 md:pt-0',
    width: '55px',
  },
  {
    id: 4,
    title: 'Assured Support for Peace of Mind',
    description:
      'Rest easy with our responsive Support and Services team at the core of the Marketplace. We prioritize your confidence by swiftly addressing functional needs. Whether it involves creating new solutions to meet evolving requirements or adding fresh features to existing products, we are agile in responding to your critical needs.',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/Icon-Always-Ready-Support-Color.png',
    alt: '',
    className: 'px-6 pt-4 md:pt-0',
    width: '55px',
  },
];

export const checks = [
  {
    id: 1,
    title: 'Hosted PowerSchool SIS',
    className: 'check-list mb-3',
    icon: CheckCircleIcon,
  },
  {
    id: 2,
    title: 'Enrollment/Enrollment Express',
    className: 'check-list mb-3',
    icon: CheckCircleIcon,
  },
  {
    id: 3,
    title: 'Ecollect Forms',
    className: 'check-list mb-3',
    icon: CheckCircleIcon,
  },
  {
    id: 4,
    title: 'Document Management',
    className: 'check-list mb-3',
    icon: CheckCircleIcon,
  },
  {
    id: 5,
    title: 'Student Analytics',
    className: 'check-list',
    icon: CheckCircleIcon,
  },
];

export const arrowList = [
  {
    id: 1,
    title: 'Digital Document Delivery',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 2,
    title: 'Mobile App Development',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 3,
    title: 'Responsive Web Design',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 4,
    title: 'Cloud Computing Solutions',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 5,
    title: 'eCommerce Development',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 6,
    title: 'UI/UX Design Services',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 7,
    title: 'Blockchain Development',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 8,
    title: 'Cybersecurity Services',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 9,
    title: 'Data Analytics Solutions',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 10,
    title: 'Artificial Intelligence',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 11,
    title: 'Internet of Things (IoT)',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 12,
    title: 'Augmented Reality (AR)',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 13,
    title: 'Virtual Reality (VR)',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
  {
    id: 14,
    title: 'Big Data Solutions',
    className: 'action-list mb-3 text-base sm:text-lg',
    icon: ArrowRightIcon,
  },
];
export const tabs = [
  {
    id: 1,
    key: '1',
    label: 'Technology',
    value: '',
    title: 'Student Information Cloud',
    description: "Secure Student Data at Your Fingertips",
    img: 'https://www.powerschool.com/wp-content/uploads/2023/05/student-info-systems-tech-420x420-1-420x420.png',
    alt: '',
    link:'https://www.powerschool.com/student-information-cloud/',
    checkList: [
      {
        id: 1,
        title: 'Hosted PowerSchool SIS',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'Enrollment/Enrollment Express',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Ecollect Forms',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 4,
        title: 'Document Management',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 6,
        title: 'Special Programs',
        className: 'check-list',
        icon: CheckIcon,
      },
    ],
  },
  {
    id: 2,
    key: '2',
    label: 'Academic',
    value: '',
    title: 'Personalized Learning Cloud',
    description: 'Empower Teachers to Enhance Student Outcomes',
    img: 'https://www.powerschool.com/wp-content/uploads/2023/02/personalized-learning-academic-1-420x420.png',
    alt: '',
    link:'https://www.powerschool.com/personalized-learning-cloud/',
    checkList: [
      {
        id: 1,
        title: 'Schoology Learning',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'Performance Matters',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Curriculum and Instruction',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 4,
        title: 'ContentNav',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 5,
        title: 'LearningNav (coming soon)',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 6,
        title: 'Personalized Homework (coming soon)',
        className: 'check-list',
        icon: CheckIcon,
      },
    ],
  },
  {
    id: 3,
    key: '3',
    label: 'Professional Learning',
    value: '',
    title: 'Educator Effectiveness Cloud',
    description:
      'Personalized Professional Development for Educator Success',
    img: 'https://www.powerschool.com/wp-content/uploads/2024/01/educator-effectiveness-persona-010324.png',
    alt: '',
    link:'https://www.powerschool.com/educator-effectiveness-cloud/',
    checkList: [
      {
        id: 1,
        title: 'Perform',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'Professional Learning',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Premium Library',
        className: 'check-list',
        icon: CheckIcon,
      }
    ],
  },
  {
    id: 4,
    key: '4',
    label: 'Student Services & Support',
    value: '',
    title: 'Student Success Cloud',
    description:
      'Support the Needs of the Whole Child',
    img: 'https://www.powerschool.com/wp-content/uploads/2023/05/student-success-support-420x420-1-420x420.png',
    alt: '',
    link:'https://www.powerschool.com/student-success-cloud/',
    checkList: [
      {
        id: 1,
        title: 'MTSS',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'Attendance Intervention',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Behavior Support',
        className: 'check-list',
        icon: CheckIcon,
      },
    ],
  },
  {
    id: 5,
    key: '5',
    label: 'HR & Finance',
    value: '',
    title: 'HR & ERP Cloud',
    description:
      'Simplify K-12 HR and Financial Management',
    img: 'https://www.powerschool.com/wp-content/uploads/2023/05/educator-recruitment-hr-420x420-1-420x420.png',
    alt: '',
    link:'https://www.powerschool.com/hr-erp-cloud/',
    checkList: [
      {
        id: 1,
        title: 'Employee Records',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'SchoolSpring Job Board',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Applicant Tracking',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 4,
        title: 'SmartFind Express',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 5,
        title: 'Candidate Assessment',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 6,
        title: 'ERP',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 7,
        title: 'HRMS',
        className: 'check-list',
        icon: CheckIcon,
      },
    ],
  },
  {
    id: 6,
    key: '6',
    label: 'College and Career',
    value: '',
    title: 'College, Career, and Life Readiness Cloud',
    description:
      'Provide the Complete Support Students Need to Be Ready for Their Futures',
    img: 'https://www.powerschool.com/wp-content/uploads/2023/05/workforce-counseling-420x420-1-420x420.png',
    alt: '',
    link:'https://www.powerschool.com/workforce-development-cloud/',
    checkList: [
      {
        id: 1,
        title: 'Naviance CCLR',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 2,
        title: 'Headed2',
        className: 'check-list',
        icon: CheckIcon,
      },
      {
        id: 3,
        title: 'Connected Intelligence P20W',
        className: 'check-list',
        icon: CheckIcon,
      }
    ],
  },
];

export const cta = [
  {
    id: 1,
    img: 'https://www.powerschool.com/wp-content/uploads/2023/05/cloud-bundles-thumbnail-612x413-1.jpeg',
    alt: '',
  },
];

export const submissionTiles = [
  {
    id: 1,
    title: 'Research and Preparation',
    description:
      'PowerSchool Product and Services experts are always monitoring and adapting to the education market’s changing needs. By anticipating changes to our customers’ needs, our team can rapidly develop new solutions completely integrated into the products they support.',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/HR-UT-Perform-Benefits-03.png',
    alt: '...',
    width: '55px',
    link: '#',
    linkText: 'Marketplace Guidelines',
  },
  {
    id: 2,
    title: 'App Development and Submission',
    description:
      'Products and components released into PowerPack are bundled together to save your school or district money. Often, the functionality and content provided in the bundle can consolidate software solutions in other areas of school business with a single, low price point.',
    img: 'https://www.powerschool.com/wp-content/uploads/2021/12/Icon-Finance-ROI-Color.png',
    alt: 'App Development and Submission',
    width: '55px',
    link: '#',
    linkText: 'Testing Environment',
  },
  {
    id: 3,
    title: 'Review Process and Approval',
    description:
      'Always completely integrated into core products, PowerPack solutions offer plug-and-play installation that saves your team time and potential headaches. A la carte options give you the flexibility to focus on implementing only the products that fit your school or district’s needs.',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/Icon-3-Clicks-Color.png',
    alt: 'Review Process and Approval',
    width: '55px',
    link: '#',
    linkText: 'Tips for Faster Approval',
  },
  {
    id: 4,
    title: 'Marketplace Management',
    description:
      'With an adaptive Support and Services team at the core of PowerPack, we’re able to respond to functionality needs quickly. From creating new PowerPack dashboards to meet changing ESSA requirements to adding new mobile functionality to PowerSchool SIS, we can rapidly respond to your critical needs.',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/01/Icon-Always-Ready-Support-Color.png',
    alt: 'Marketplace Management',
    width: '55px',
    link: '#',
    linkText: 'Key Metrics to Monitor',
  },
];

export const pluginCardsRecommended = [
  {
    id: 5,
    title: 'Records Request Tracking',
    category: 'Dashboard',
    tag: 'Records',
    description: 'Track and manage records requests with ease.',
    downloads: '2.2K',
    rating: '4.7',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/06/small-schools-bundles-feature-image-ui-student-essentials.jpg',
    alt: '',
    isRecommended: true,
  },
  {
    id: 6,
    title: 'Project Management Dashboard',
    category: 'Dashboard',
    tag: 'Project Management',
    description:
      'Manage and track projects, tasks, and team members with an intuitive dashboard.',
    downloads: '6.8K',
    rating: '4.5',
    img: 'https://www.powerschool.com/wp-content/uploads/2022/06/small-schools-bundles-feature-image-ui-student-essentials.jpg',
    alt: '',
    isRecommended: true,
  },
];
export const pluginCardsDashboard = [
  {
    id: 7,
    title: 'Virtual Classroom Assistant',
    category: 'Dashboard, AI',
    tag: 'Staff Pick',
    description:
      'Real-time collaboration tools, attendance tracking, and interactive quizzes for virtual classrooms.',
    downloads: '2.2K',
    rating: '4.7',
    alt: '',
    isRecommended: false,
    img: '',
  },
  {
    id: 8,
    title: 'Language Learning Assistant',
    category: 'Education, AI',
    tag: 'Language Learning',
    description:
      'Learn a new language with interactive lessons, quizzes, and practice exercises.',
    downloads: '6.7K',
    rating: '4.8',
    isRecommended: false,
    img: '',
    alt: '',
  },
];

export const pluginCardsData = [
  {
    title: 'Smart Home Controller',
    category: 'Dashboard, IoT',
    tag: 'Smart Home',
    description: 'Control and manage all your smart home devices in one place.',
    downloads: '5.1K',
    rating: '4.3',
    isRecommended: false,
    img: '',
    alt: '',
  },
  {
    id: 9,
    title: 'Fitness Tracker',
    category: 'Health & Fitness, Mobile',
    tag: 'Tracker',
    description:
      'Track your daily activity, workouts, and sleep to stay fit and healthy.',
    downloads: '8.6K',
    rating: '4.9',
    isRecommended: false,
    img: '',
    alt: '',
  },
  {
    id: 10,
    title: 'Expense Tracker',
    category: 'Finance, Mobile',
    tag: 'Tracker',
    description:
      'Track and manage your expenses on the go, and stay on top of your budget.',
    downloads: '10.3K',
    rating: '4.6',
    isRecommended: false,
    img: '',
    alt: '',
  },
  {
    id: 11,
    title: 'Recipe Finder',
    category: 'Food & Drink, Mobile',
    tag: 'Recipes',
    description:
      'Discover new recipes, save your favorites, and create shopping lists.',
    downloads: '3.8K',
    rating: '4.2',
    isRecommended: false,
    img: '',
    alt: '',
  },
];

export const sidebarFilters = [
  {
    key: 1,
    label: 'Category',
    name: '',
    id: '1',
  },
  {
    key: 2,
    label: 'User',
    name: '',
    id: '2',
  },
  {
    key: 3,
    label: 'Downloads',
    name: '',
    id: '3',
  },
];

export const sidebarRadioButtons = [
  {
    key: 1,
    name: 'All',
  },
  {
    key: 2,
    name: 'Free',
  },
  {
    key: 3,
    name: 'Paid',
  },
];

export const submitOptions = [
  {
    key: 0,
    name: '-',
  },
  {
    key: 1,
    name: 'AI 101',
  },
  {
    key: 2,
    name: 'Academics',
  },
  {
    key: 3,
    name: 'Technology',
  },
  {
    key: 4,
    name: 'Distance Learning',
  },
];

export const appImages = [
  {
		id: 1,
    url: '/files/Applications/PowerPack-Flex-Emailer-desktop-072522.png',
    caption: '',
		featured: false
  },
  {
		id: 2,
    url: '/files/Applications/PowerPack-Doc-Management.png',
    caption: '',
		featured: false
  },
  {
		id: 3,
    url: '/files/Applications/PowerPack-Doc-Signature.png',
    caption: '',
		featured: false
  },
];
